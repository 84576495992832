import classes from "./Footer.module.css";
import CallIcon from "@mui/icons-material/Call";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import Bambi from "../assets/home_page/bambi-transparent-min-right.png";
import { useMediaQuery } from "@mui/material";

const Footer = () => {
  const smallDevice = useMediaQuery("(max-width: 1000px)");

  return (
    <footer data-testid="footer-id" className={classes.footer}>
      <img
        src={Bambi}
        alt="Bambi"
        width={146}
        height={207}
        style={{
          position: "absolute",
          right: `${smallDevice ? "-20px" : "10%"}`,
          bottom: `${smallDevice ? "0" : "20%"}`,
          width: "200px !important",
          height: "200px !important",
          zIndex: 10,
        }}
      />
      <section className={classes.cover_container}>
        <h1 className={classes.footerTopTitle}>Bambi Play dečja igraonica</h1>
        <div className={classes.footerBottomContainer}>
          <div className={classes.singleContainer}>
            <h2 className={classes.footerTitle}>Kontakt</h2>
            <p className={classes.footerLogo}>
              <CallIcon color="inherit" fontSize="inherit" />
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Telefon: </span>
              <a href="tel:0600110110">060/011 011 0</a>{" "}
            </p>
          </div>
          <div className={classes.singleContainer}>
            <h2 className={classes.footerTitle}>Radno vreme</h2>
            <p className={classes.footerLogo}>
              <AccessTimeIcon color="inherit" fontSize="inherit" />
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Pon-Pet: </span> 14:00 – 21:00
              h{" "}
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>Sub-Ned: </span> 10:00 – 21:00
              h{" "}
            </p>
          </div>
          <div className={classes.singleContainer}>
            <h2 className={classes.footerTitle}>Adresa</h2>
            <p className={classes.footerLogo}>
              <LocationCityIcon color="inherit" fontSize="inherit" />
            </p>
            <p>
              <span style={{ fontWeight: "600" }}>
                Braće Vučković 27, Bele Vode
              </span>{" "}
            </p>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
