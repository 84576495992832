import classes from "./Catering.module.css";
import Chef from "../assets/chef-1.png";
import { Box, Button, Typography } from "@mui/material";
import One from "../assets/catering/1.webp";
import { Link, useNavigate } from "react-router-dom";
import FlatwareIcon from "@mui/icons-material/Flatware";
import TextReveal from "../animations/TextReveal/TextReveal";
import useCustomObserver from "../hooks/customObserver";

const CateringHome = () => {
  const { sectionRef, inView } = useCustomObserver(0.5);
  const navigate = useNavigate();

  const handleImageNavigate = () => {
    navigate("/catering");
  };

  return (
    <Box className={classes.containerHome} ref={sectionRef}>
      <Typography
        variant="h4"
        width={"100%"}
        textAlign={"center"}
        sx={{
          paddingTop: "20px",
          color: (theme: any) => theme.palette.secondary.main,
        }}
      >
        Ketering za svačiji ukus
      </Typography>
      <TextReveal
        inView={inView}
        duration={3}
        customStyle={{
          display: "flex",
          width: "100%",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box className={classes.imageContainerHome}>
          <img src={Chef} alt="chef" className={classes.chef} />
        </Box>
        <Box className={classes.buttonCateringHome}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/catering"
            startIcon={<FlatwareIcon />}
            sx={{
              width: "200px",
              margin: "20px",
              padding: "10px",
              fontWeight: "bold",
            }}
          >
            Ketering
          </Button>
        </Box>
        <Box className={classes.imageContainerHome}>
          <img
            src={One}
            alt="catering"
            className={classes.chef}
            onClick={handleImageNavigate}
          />
        </Box>
      </TextReveal>
    </Box>
  );
};

export default CateringHome;
