import { Typography } from "@mui/material";
import classes from "./HeaderTop.module.css";
import PhoneIcon from "@mui/icons-material/Phone";
import { useNavigate } from "react-router-dom";

const HeaderTop = () => {
  const navigate = useNavigate();
  return (
    <section className={classes.container}>
      <Typography variant="body1" color={"secondary.main"}>
        <a
          onClick={(e) => {
            e.preventDefault();
            navigate("/");
          }}
          href="/"
        >
          Dečja igraonica
        </a>{" "}
        <span>Bele Vode</span>
      </Typography>

      <div>
        <a
          className={classes.instaContainer}
          href="tel:0600110110"
          style={{ marginLeft: "3px" }}
        >
          <span>Telefon: </span>
          <PhoneIcon color="inherit" fontSize="inherit" />
          060/011 011 0
        </a>
      </div>
      <div>
        <a
          className={classes.instaContainer}
          href="https://www.instagram.com/igraonica.bambi/"
          target="_blank"
          rel="noreferrer"
        >
          <span>Instagram </span>
          <div className={classes.instaLogo}></div>
        </a>
      </div>
    </section>
  );
};

export default HeaderTop;
