import { Typography } from "@mui/material";
import { motion } from "framer-motion";

const WaveText = ({
  text,
  inView,
  variant = "h4",
}: {
  text: string;
  inView: boolean;
  variant: any;
}) => {
  return (
    <>
      {text.split("").map((char, index) => (
        <motion.span
          key={index}
          style={{ display: "inline-block" }}
          initial={{ y: 0 }}
          animate={
            inView
              ? {
                  y: [0, -10, 0, 10, 0], // Wave motion for each letter
                }
              : { y: 0 }
          }
          transition={{
            delay: index * 0.1, // Stagger the animation for each letter
            repeat: Infinity,
            duration: 2.0, // Smooth wave duration
          }}
        >
          {char === " " ? "\u00A0" : char} {/* Render non-breaking space */}
        </motion.span>
      ))}
    </>
  );
};

export default WaveText;
