import { ThemeProvider, CssBaseline } from "@mui/material";
import "./App.css";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Main from "./Main/Main";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Gallery from "./Galery/Gallery";
import PriceList from "./PriceList/PriceList";
import { AnimatePresence } from "framer-motion";
import lightTheme from "./themeMUI/lightTheme";
import Catering from "./Catering/Catering";

function App() {
  return (
    <ThemeProvider theme={lightTheme}>
      <CssBaseline />
      <div className="App">
        <BrowserRouter>
          <AnimatePresence>
            <Header key="header" />
            <Routes>
              <Route path="/*" element={<Main />} />
              <Route path="/galery" element={<Gallery />} />
              <Route path="/price-list" element={<PriceList />} />
              <Route path="/catering" element={<Catering />} />
            </Routes>
            <Footer key="footer" />{" "}
          </AnimatePresence>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
