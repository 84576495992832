import { createTheme } from "@mui/material";

const lightTheme = createTheme({
  palette: {
    white: "#ffffff",
    black: "#000000",
    primary: {
      main: "#fcbd3e", // orange,
      secondary: "#d98324", // dark orange
    },
    secondary: {
      main: "#673ab7", // purple
    },
    text: {
      primary: "#2e2d2c", // custom dark gray
    },
    background: {
      default: "radial-gradient(#fcbd3e,#d98324)", // Gradient background
      paper: "#ffffff", // Default paper color
    },
  },
  typography: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif", // Adjust if needed
  },
});

export default lightTheme;
