import React from "react";
import classes from "./Map.module.css";
import { Box, Typography } from "@mui/material";
import Confused from "../assets/home_page/confused-kid.png";
import TextReveal from "../animations/TextReveal/TextReveal";
import useCustomObserver from "../hooks/customObserver";

const Map: React.FC<{}> = () => {
  const { sectionRef, inView } = useCustomObserver(0.2);

  return (
    <TextReveal inView={inView} duration={2}>
      <Typography
        ref={sectionRef}
        variant="h4"
        width={"100%"}
        sx={{ textAlign: "center", margin: "20px 0", fontWeight: "700" }}
        color={(theme) => theme.palette.secondary.main}
      >
        Gde se nalazimo?
      </Typography>
      <Box component={"section"} className={classes.container}>
        <Box className={classes.imageContainer}>
          <img src={Confused} alt="Confused" width={300} height={300} />
        </Box>
        <Box
          className={classes.map}
          dangerouslySetInnerHTML={{
            __html: `<iframe title="a" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d11333.187216820834!2d20.4099717!3d44.7543613!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x68066463cd3acef4!2sBambi%20Play%20Igraonica!5e0!3m2!1ssr!2srs!4v1670368513300!5m2!1ssr!2srs" style="border:0; width: 95%; max-width: 700px; height: 400px; margin: 10px auto;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`,
          }}
        ></Box>
      </Box>
    </TextReveal>
  );
};

export default Map;
