import { useEffect, useState } from "react";
import classes from "./Catering.module.css";
import ImageItem from "../Galery/ImageItem/ImageItem";
import CoolLightbox from "../Galery/LightBox/CoolLightbox";
import { ImagesListType } from "react-spring-lightbox";
import { useGalleryImages } from "../Hooks/useGaleryImages";
import Chef from "../assets/chef-1.png";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";

const Catering = () => {
  const smallDevice = useMediaQuery("(max-width:900px)");
  const { images } = useGalleryImages(true);
  const [isLoaded, setIsLoaded] = useState(false);

  const [lightbox, setLightBox] = useState({ isOpen: false, position: 0 });

  const imagesLightBox: ImagesListType = [];

  images.forEach((element, index) => {
    imagesLightBox[index] = {
      src: element.currentSrc,
      loading: "lazy",
      alt: `catering image ${index}`,
    };
  });

  useEffect(() => {
    window.scroll(0, 0);
    setIsLoaded(true);
  }, [images.length]);

  useEffect(() => {
    const handlePopState = () => {
      // Close the lightbox if back button is pressed
      if (lightbox.isOpen) {
        setLightBox({ isOpen: false, position: 0 });
      }
    };

    // Listen for back navigation
    window.addEventListener("popstate", handlePopState);

    // Push state to the history to prevent page navigation
    if (lightbox.isOpen) {
      window.history.pushState(null, "", window.location.href);
    }

    // Cleanup
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [lightbox.isOpen]);

  const toggleLightBoxHandler = (isOpen: boolean, position: number) => {
    if (isOpen) {
      // Push to history when lightbox is opened
      window.history.pushState(null, "", window.location.href);
    } else {
      // Pop the history entry when closing the lightbox
      window.history.back();
    }
    setLightBox({ isOpen, position });
  };

  return (
    <Box className={classes.containerBackdrop}>
      <Box
        className={classes.container}
        sx={{
          gap: smallDevice ? "0.5rem" : "2.2rem",
        }}
      >
        <Typography
          variant="h4"
          width={"100%"}
          textAlign={"center"}
          sx={{
            flex: "1 1 100%",
            padding: "1rem 1rem 0 1rem",
            color: (theme: any) => theme.palette.secondary.main,
          }}
        >
          Ketering za svačiji ukus
        </Typography>
        <Box className={classes.imageContainer}>
          <img src={Chef} alt="chef" className={classes.chef} />
        </Box>
        <Typography className={classes.title} fontSize={22}>
          Nudimo ukusne specijalitete sa roštilja, sveže pripremljene salate,
          domaće pite i još mnogo toga. Naš ketering se ponosi timom iskusnih
          kuvara sa dugogodišnjim iskustvom, koji sa pažnjom biraju
          najkvalitetnije sastojke i pripremaju svaki obrok s ljubavlju. Bez
          obzira na to da li organizujete dečiji rođendan, porodično slavlje ili
          poslovni događaj, garantujemo vrhunski ukus i besprekornu uslugu.
          Prepustite se uživanju, dok mi brinemo o savršenom zalogaju za vaše
          goste! 🎉🍴
        </Typography>
        {lightbox.isOpen ? (
          <Button
            onClick={() => toggleLightBoxHandler(false, 0)} // close the lightbox
            style={{
              position: "fixed",
              top: "80px",
              right: "20px",
              background: "rgba(255, 255, 255, 0.8)",
              color: "orange",
              border: "none",
              borderRadius: "50%",
              padding: "10px",
              fontSize: "20px",
              zIndex: 1000,
              fontWeight: "bold",
            }}
          >
            X
          </Button>
        ) : null}
        <Box className={classes.wraper}>
          {isLoaded && imagesLightBox.length > 3 ? (
            <CoolLightbox
              data-testid="coollightbox"
              isOpen={lightbox.isOpen}
              position={lightbox.position}
              images={imagesLightBox}
              toggleVisibility={toggleLightBoxHandler}
            />
          ) : null}
          {images.map((element, index) => {
            return (
              <ImageItem
                key={`${element.id} ${index}`}
                src={element.src}
                alt={`catering image ${index}`}
                index={index}
                open={toggleLightBoxHandler}
              />
            );
          })}
        </Box>
      </Box>
    </Box>
  );
};

export default Catering;
