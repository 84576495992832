import { motion } from "framer-motion";

const Rotating = ({ children, ...rest }) => {
  return (
    <motion.div
      key="logo-animation"
      animate={{
        scale: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
        rotate: [180, 360],
        borderRadius: ["20%", "20%", "50%", "50%", "20%"],
      }}
      transition={{ ease: "linear", duration: 1.5 }}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

export default Rotating;
