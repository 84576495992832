import React from "react";
import classes from "./TestimonialItem.module.css";
import StarIcon from "@mui/icons-material/Star";
import { Box, Typography } from "@mui/material";

type Props = {
  name: string;
  text: string;
};

const TestimonialItem: React.FC<Props> = ({ name, text }) => {
  return (
    <Box className={classes.container}>
      <Box className={classes.name}>{name}</Box>
      <Typography className={classes.text}>{text}</Typography>
      <Box className={classes.stars}>
        <StarIcon fontSize="inherit" color="inherit" />
        <StarIcon fontSize="inherit" color="inherit" />
        <StarIcon fontSize="inherit" color="inherit" />
        <StarIcon fontSize="inherit" color="inherit" />
        <StarIcon fontSize="inherit" color="inherit" />
      </Box>
    </Box>
  );
};

export default TestimonialItem;
