import { useEffect, useState } from "react";
import slider_1 from "../assets/galery/bambi-1.webp";
import slider_2 from "../assets/galery/bambi-2.webp";
import slider_3 from "../assets/galery/bambi-3.webp";
import slider_4 from "../assets/galery/bambi-4.webp";
import slider_5 from "../assets/galery/bambi-5.webp";

import slider_6 from "../assets/galery/bambi-6.webp";
import slider_7 from "../assets/galery/bambi-7.webp";
import slider_8 from "../assets/galery/bambi-8.webp";
import slider_9 from "../assets/galery/bambi-9.webp";
import slider_10 from "../assets/galery/bambi-10.webp";

import slider_11 from "../assets/galery/bambi-11.webp";
import slider_12 from "../assets/galery/bambi-12.webp";
import slider_13 from "../assets/galery/bambi-13.webp";
import slider_14 from "../assets/galery/bambi-14.webp";
import slider_15 from "../assets/galery/bambi-15.webp";
import slider_16 from "../assets/galery/bambi-16.webp";
import slider_17 from "../assets/galery/bambi-17.webp";
import slider_18 from "../assets/galery/bambi-18.webp";
import slider_19 from "../assets/galery/bambi-19.webp";

import slider_20 from "../assets/galery/bambi-20.webp";
import slider_21 from "../assets/galery/bambi-21.webp";
import slider_22 from "../assets/galery/bambi-22.webp";
import slider_23 from "../assets/galery/bambi-23.webp";
import slider_24 from "../assets/galery/bambi-24.webp";

import one from "../assets/catering/1.webp";
import two from "../assets/catering/2.webp";
import three from "../assets/catering/3.webp";
import four from "../assets/catering/4.webp";
import five from "../assets/catering/5.webp";

const caterginRowImages = [
  {
    url: one,
  },
  {
    url: two,
  },
  {
    url: three,
  },
  {
    url: four,
  },
  {
    url: five,
  },
];

const galleryRowImages = [
  {
    url: slider_1,
  },
  {
    url: slider_2,
  },
  {
    url: slider_3,
  },
  {
    url: slider_4,
  },
  {
    url: slider_5,
  },
  {
    url: slider_6,
  },
  {
    url: slider_7,
  },
  {
    url: slider_8,
  },
  {
    url: slider_9,
  },
  {
    url: slider_10,
  },
  {
    url: slider_11,
  },
  {
    url: slider_12,
  },
  {
    url: slider_13,
  },
  {
    url: slider_14,
  },
  {
    url: slider_15,
  },
  {
    url: slider_16,
  },
  {
    url: slider_17,
  },
  {
    url: slider_18,
  },
  {
    url: slider_19,
  },
  {
    url: slider_20,
  },
  {
    url: slider_21,
  },
  {
    url: slider_22,
  },
  {
    url: slider_23,
  },
  {
    url: slider_24,
  },
];

const SliderImages: HTMLImageElement[] = [];

export const useGalleryImages = (isCatering: boolean) => {
  const [images, setImages] = useState(SliderImages);

  const imageArray = isCatering ? caterginRowImages : galleryRowImages;

  useEffect(() => {
    async function hellper() {
      const temp: HTMLImageElement[] = [];
      imageArray.forEach((element, index) => {
        temp[index] = new Image();
        temp[index].src = element.url;
      });

      setImages(() => [...temp]);
    }

    if (images.length === 0) {
      hellper();
    }
  }, []);

  return { images: images };
};
