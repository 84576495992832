import { motion } from "framer-motion";

const TextReveal = ({
  children,
  duration = 5,
  inView = true,
  customStyle = null,
}: any) => {
  return (
    <motion.div
      key={`reveal-animation`}
      initial={{ width: "0%" }}
      animate={inView ? { width: "100%" } : { width: "0%" }}
      transition={{ duration: duration }}
      style={
        customStyle
          ? { ...customStyle }
          : {
              overflow: "hidden",
              whiteSpace: "nowrap",
              display: "inline-block",
            }
      }
    >
      {children}
    </motion.div>
  );
};

export default TextReveal;
