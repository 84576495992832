import LogoImage from "../assets/bambi-play-transparent.png";
import Rotating from "../animations/Rotating/Rotating";
import classes from "./Logo.module.css";

const Logo = () => {
  return (
    <Rotating className={classes.container}>
      <img src={LogoImage} alt="logo" />
    </Rotating>
  );
};

export default Logo;
