import Map from "../Map/Map";
import Rodjendaonica from "../Rodjendaonica/Rodjendaonica";
import SliderTop from "../Sliders/SliderTop";
import Testimonials from "../Testimonials/Testimonials";
import GeneratePrice from "../components/GeneratePrice/GeneratePrice";
import Rotating from "../animations/Rotating/Rotating";
import Flying from "../animations/Flying/Flying";
import CateringHome from "../Catering/CateringHome";

const Main = () => (
  <>
    <Flying />
    <Rotating>
      <SliderTop />
    </Rotating>
    <Rodjendaonica />
    <GeneratePrice />
    <CateringHome />
    <Testimonials />
    <Map />
  </>
);

export default Main;
