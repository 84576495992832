import { Link } from "react-router-dom";
import classes from "./Rodjendaonica.module.css";
import { Box, Button, Typography, useMediaQuery } from "@mui/material";
import EuroIcon from "@mui/icons-material/Euro";
import Image_1 from "../assets/home_page/igraonica_1-min.png";
import Image_2 from "../assets/home_page/igraonica_2-min.png";
import useCustomObserver from "../hooks/customObserver";
import Divider from "@mui/material/Divider";
import WaveText from "../animations/WaveText/WaveText";
import TextReveal from "../animations/TextReveal/TextReveal";

const Rodjendaonica = () => {
  const { sectionRef, inView } = useCustomObserver();
  const smallDevice = useMediaQuery("(max-width:900px)");
  return (
    <Box className={classes.top_segment} ref={sectionRef}>
      <Box className={classes.segment_container}>
        <Box className={classes.imageContainer}>
          <img src={Image_1} alt="igraonica" />
        </Box>
        <Box className={classes.container}>
          <Typography
            variant="h4"
            sx={{
              color: "secondary.main",
            }}
          >
            {
              <WaveText
                text="Dnevna Igraonica"
                inView={inView}
                variant={"h4"}
              />
            }
          </Typography>
          <Typography variant="body1" p={4} fontSize={smallDevice ? 16 : 22}>
            {`Kada nemamo organizovane rođendane možete dovesti vašu decu da se
            bezbrižno igraju u našoj veseloj igraonici. Prostor igraonice je
            pregledan i možete uvek vaše mališane držati na oku dok se opuštate
            u našem kafiću. Cena dnevne Bambi Play karte je samo 350 rsd.`}
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{ width: "90%", margin: "auto" }}
        className={classes.divider}
      />
      <Box className={classes.segment_container}>
        <Box className={classes.imageContainer}>
          <img src={Image_2} alt="igraonica" />
        </Box>
        <Box className={classes.container}>
          <Typography
            variant="h4"
            sx={{
              color: "secondary.main",
            }}
          >
            {
              <WaveText
                text="Proslava Rodjendana"
                inView={inView}
                variant={"h4"}
              />
            }
          </Typography>
          <Typography variant="body1" p={4} fontSize={smallDevice ? 16 : 22}>
            Organizujte rođendan kod nas i priredite vašoj deci i gostima
            nezaboravne trenutke. Raspolažemo sa 55 sedećih mesta za odrasle i
            do 40 za mališane. Cene proslave rođendana u Bambi Play igraonici su
            najpovoljnije u gradu i možete ih pogledati u našem cenovniku.
          </Typography>

          <Box className={classes.cenovnik}>
            <Button
              variant="contained"
              color="primary"
              component={Link}
              to="/price-list"
              startIcon={<EuroIcon />}
              sx={{
                width: "200px",
                margin: "20px",
                padding: "10px",
                fontWeight: "bold",
              }}
            >
              Cenovnik
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Rodjendaonica;
