import React, { useEffect, useState } from "react";
import classes from "./Galery.module.css";
import ImageItem from "./ImageItem/ImageItem";
import CoolLightbox from "./LightBox/CoolLightbox";
import { ImagesListType } from "react-spring-lightbox";
import { useGalleryImages } from "../Hooks/useGaleryImages";
import TextReveal from "../animations/TextReveal/TextReveal";
import { Button } from "@mui/material";

const Gallery = () => {
  const { images } = useGalleryImages(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const [lightbox, setLightBox] = useState({ isOpen: false, position: 0 });

  let imagesLightBox: ImagesListType = [];

  images.forEach((element, index) => {
    imagesLightBox[index] = {
      src: element.currentSrc,
      loading: "lazy",
      alt: `galery image ${index}`,
    };
  });

  useEffect(() => {
    setIsLoaded(true);
  }, [images.length]);

  useEffect(() => {
    const handlePopState = () => {
      // Close the lightbox if back button is pressed
      if (lightbox.isOpen) {
        setLightBox({ isOpen: false, position: 0 });
      }
    };

    // Listen for back navigation
    window.addEventListener("popstate", handlePopState);

    // Push state to the history to prevent page navigation
    if (lightbox.isOpen) {
      window.history.pushState(null, "", window.location.href);
    }

    // Cleanup
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [lightbox.isOpen]);

  const toggleLightBoxHandler = (isOpen: boolean, position: number) => {
    if (isOpen) {
      // Push to history when lightbox is opened
      window.history.pushState(null, "", window.location.href);
    } else {
      // Pop the history entry when closing the lightbox
      window.history.back();
    }
    setLightBox({ isOpen, position });
  };

  return (
    <section className={classes.container}>
      {lightbox.isOpen ? (
        <Button
          onClick={() => toggleLightBoxHandler(false, 0)} // close the lightbox
          style={{
            position: "fixed",
            top: "80px",
            right: "20px",
            background: "rgba(255, 255, 255, 0.8)",
            color: "orange",
            border: "none",
            borderRadius: "50%",
            padding: "10px",
            fontSize: "20px",
            zIndex: 1000,
            fontWeight: "bold",
          }}
        >
          X
        </Button>
      ) : null}
      <div className={classes.wraper}>
        {isLoaded && imagesLightBox.length > 6 ? (
          <CoolLightbox
            data-testid="coollightbox"
            isOpen={lightbox.isOpen}
            position={lightbox.position}
            images={imagesLightBox}
            toggleVisibility={toggleLightBoxHandler}
          />
        ) : null}
        <TextReveal
          duration={0.7}
          customStyle={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {images.map((element, index) => {
            return (
              <ImageItem
                key={`${element.id} ${index}`}
                src={element.src}
                alt={`galery image ${index}`}
                index={index}
                open={toggleLightBoxHandler}
              />
            );
          })}
        </TextReveal>
      </div>
    </section>
  );
};

export default Gallery;
