import { useState } from "react";
import classes from "./NavigationMain.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import PriceCheckIcon from "@mui/icons-material/PriceCheck";
import MenuIcon from "@mui/icons-material/Menu";

import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Box,
  useMediaQuery,
  Button,
} from "@mui/material";

const NavigationMain = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width:680px)");
  const path = window.location.pathname;
  const navigate = useNavigate();

  const toggleDrawer = (open: boolean) => () => {
    setIsDrawerOpen(open);
  };

  const menuItems = [
    { text: "Početna", icon: <HomeIcon fontSize="large" />, link: "/" },
    {
      text: "Galerija",
      icon: <CameraAltIcon fontSize="large" />,
      link: "/galery",
    },
    {
      text: "Cenovnik",
      icon: <PriceCheckIcon fontSize="large" />,
      link: "/price-list",
    },
    {
      text: "Ketering",
      icon: <PriceCheckIcon fontSize="large" />,
      link: "/catering",
    },
  ];
  return (
    <AppBar
      position="static"
      color="primary"
      className={classes.container}
      sx={{
        boxShadow: "none !important",
        background: "none !important",
      }}
    >
      <Toolbar>
        {isSmallScreen ? (
          <>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="menu"
              onClick={toggleDrawer(true)}
            >
              <MenuIcon
                fontSize="large"
                sx={{ border: "1px solid grey", borderRadius: "5px" }}
              />
            </IconButton>
            <Drawer
              anchor="left"
              open={isDrawerOpen}
              onClose={toggleDrawer(false)}
              sx={{
                "& .MuiDrawer-paper": {
                  width: 250,
                  marginTop: "50px !important",
                  background:
                    "radial-gradient(rgb(252 189 62), rgb(217, 131, 36))",
                },
              }}
            >
              <List>
                {menuItems.map((item) => (
                  <ListItem
                    button
                    component={NavLink}
                    to={item.link}
                    key={item.text}
                    onClick={toggleDrawer(false)}
                    sx={{
                      color: (theme) => theme.palette.secondary.main,
                      fontSize: "1.2rem !important",
                    }}
                  >
                    <ListItemIcon
                      sx={{ color: (theme) => theme.palette.secondary.main }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.text}
                      primaryTypographyProps={{
                        style: {
                          fontSize: "1.2rem",
                        },
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Drawer>
          </>
        ) : (
          <Box className={classes.navigation_menu}>
            {menuItems.map((item) => (
              <Button
                key={item.text}
                onClick={() => navigate(item.link)}
                variant={path === item.link ? "contained" : "outlined"}
                startIcon={item.icon}
                sx={{
                  padding: "5px 15px",
                  minWidth: "150px",
                  margin: "0 15px",
                  textTransform: "none",
                  fontSize: "1.2rem",
                  textDecoration: "none",
                  color: "inherit",
                  "&.active": {
                    fontWeight: "bold",
                  },
                }}
              >
                {item.text}
              </Button>
            ))}
          </Box>
        )}
      </Toolbar>
    </AppBar>
  );
};

// const NavigationMain = () => {
//   const { width } = useWindowDimensions();
//   const [isVisable, setIsVisable] = useState(false);

//   const toggleVisability = () => {
//     setIsVisable((prevState) => !prevState);
//   };

//   useEffect(() => {
//     const temp = width > 680 ? true : false;
//     setIsVisable(() => temp);
//   }, [width]);

//   return (
//     <motion.nav
//       key="nav-animation"
//       {...animateLeftRight("x", 400)}
//       className={classes.container}
//     >
//       <button className={classes.hamburger} onClick={toggleVisability}>
//         <MenuIcon color="inherit" fontSize="inherit" />
//       </button>
//       <div
//         className={classes.navigation_menu}
//         style={isVisable ? { display: "flex" } : { display: "none" }}
//       >
//         <button className={classes.hamburgerClose} onClick={toggleVisability}>
//           <CloseIcon color="inherit" fontSize="inherit" />
//         </button>
//         <ul onClick={width < 680 ? toggleVisability : () => {}}>
//           <li>
//             <NavLink
//               to="/"
//               className={({ isActive }) =>
//                 isActive ? classes.activna : classes.notActive
//               }
//             >
//               <span className={classes.navIcons}>
//                 <HomeIcon fontSize="inherit" />
//               </span>
//               Početna
//             </NavLink>
//           </li>
//           <li>
//             <NavLink
//               to="/galery"
//               className={({ isActive }) =>
//                 isActive ? classes.activna : classes.notActive
//               }
//             >
//               <span className={classes.navIcons}>
//                 <CameraAltIcon fontSize="inherit" />
//               </span>{" "}
//               Galerija
//             </NavLink>
//           </li>
//           <li>
//             <NavLink
//               to="/price-list"
//               className={({ isActive }) =>
//                 isActive ? classes.activna : classes.notActive
//               }
//             >
//               <span className={classes.navIcons}>
//                 <PriceCheckIcon fontSize="inherit" />
//               </span>{" "}
//               Cenovnik
//             </NavLink>
//           </li>
//         </ul>
//       </div>
//     </motion.nav>
//   );
// };

export default NavigationMain;
