import { motion } from "framer-motion";
import KidPlane from "../../assets/kid-plane.gif";
import { useMediaQuery } from "@mui/material";

const Flying = ({ inView = true }: { inView?: boolean }) => {
  const smallDevice = useMediaQuery("(max-width:1000px)");
  return (
    <motion.div
      key="flying-animation"
      initial={{ x: smallDevice ? "-370px" : "-600px", y: "700px" }} // Start from bottom-left
      animate={inView ? { x: "100vw", y: "-20vh" } : {}} // Move to top-right
      transition={{
        duration: 8, // Adjust the duration for the flight
        ease: "circInOut", // Smooth easing,
        delay: 2, // Delay the flight
      }}
      style={{
        position: "fixed", // Ensure it moves relative to the screen
        top: 0,
        left: 0,
        zIndex: 12,
      }}
    >
      <img
        src={KidPlane}
        width={smallDevice ? 376 : 564}
        height={smallDevice ? 315 : 473}
        alt="kid-plane"
      />
    </motion.div>
  );
};

export default Flying;
