import React, { useEffect } from "react";
import classes from "./PriceList.module.css";
import priceImage from "../assets/new-menu.jpg";
import { Box, Typography } from "@mui/material";
import GeneratePrice from "../components/GeneratePrice/GeneratePrice";

const PriceList: React.FC<{}> = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <>
      <Box className={classes.container}>
        <Typography
          variant="h5"
          sx={{
            textAlign: "center",
            color: "primary.secondary",
          }}
        >
          Ovde možete pogledati cene svih naših usluga
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            fontWeight: "600",
            marginTop: "20px",
          }}
        >
          Cene zakupa igraonice sa satnicama
        </Typography>
        <ul>
          <li>
            <span className={classes.leftSpan}>Pon-Pet (18h-20:30h)</span>{" "}
            <span className={classes.rightSpan}>9 000 rsd</span>
          </li>
          <li>
            <span className={classes.leftSpan}>
              Sub-Ned (12h - 14:30h, 15h-17:30h, 18h-20:30h)
            </span>
            <span className={classes.rightSpan}>10 000 rsd</span>
          </li>
        </ul>
        <h4>Zakup prostora obuhvata</h4>
        <ul className={classes.bottomUL}>
          <li>Zakup igraonice</li>
          <li>Osoblje koje poslužuje roditelje (konobar i šanker)</li>
          <li>Profesionalni animatori (šminkanje i crtanje po licu)</li>
          <li>Pribor za serviranje hrane</li>
          <li>Elektronske pozivnice</li>
          <li>Fotografisanje</li>
        </ul>
        <div className={classes.containerImg}>
          <h4>Naša karta pića</h4>
          <img src={priceImage} alt="price list" />
        </div>
      </Box>
      <GeneratePrice />
    </>
  );
};

export default PriceList;
